import React, { Component, Fragment } from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import './ProductList.css'

class Product extends Component {
  getSubcategoryDisabled = () => {
    return this.props.selectedLossCode ? true : false
  }

  renderUpdateButton = () => {
    const {
      brand,
      handleUpdateProduct,
      loading,
      model,
      productPurchaseDate,
      subCategory,
      selectedProduct,
      serial,
    } = this.props
    let hasDifference =
      (selectedProduct.mfg.toUpperCase() !== brand.toUpperCase() && brand.length > 0) ||
      (selectedProduct.modelNumber.toUpperCase() !== model.toUpperCase() && model.length > 0) ||
      (selectedProduct.serial.toUpperCase() !== serial.toUpperCase() && serial.length > 0) ||
      moment(selectedProduct.productPurchaseDate).format('MM/DD/YYYY') !== productPurchaseDate ||
      (selectedProduct.subCategory !== subCategory && subCategory.length > 0)
    const buttonJSX = (
      <Button
        size='md'
        className='RoundedButton'
        onClick={() => handleUpdateProduct()}
        variant='outline-info'
        disabled={!hasDifference || loading}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
        <span className='ps-1'>Update</span>
      </Button>
    )

    return buttonJSX
  }

  renderResetButton = () => {
    const { brand, handleResetProduct, loading, model, subCategory, selectedProduct, serial } =
      this.props

    let hasDifference =
      (selectedProduct.mfg.toUpperCase() !== brand.toUpperCase() && brand.length > 0) ||
      (selectedProduct.modelNumber.toUpperCase() !== model.toUpperCase() && model.length > 0) ||
      (selectedProduct.serial.toUpperCase() !== serial.toUpperCase() && serial.length > 0) ||
      (selectedProduct.subCategory !== subCategory && subCategory.length > 0)

    return (
      <Button
        size='md'
        className='RoundedButton'
        variant='outline-info'
        onClick={() => handleResetProduct()}
        disabled={!hasDifference || loading}
      >
        <FontAwesomeIcon icon={faEraser} />
        <span className='ps-1'>Reset</span>
      </Button>
    )
  }

  render() {
    const {
      brand,
      handleInputChange,
      isBrandValid,
      isBrandValidated,
      isModelValid,
      isModelValidated,
      isSubcategoryValid,
      isSubcategoryValidated,
      isSerialValid,
      isSerialValidated,
      manufacturers,
      model,
      subcategories,
      subCategory,
      productPurchaseDate,
      purchasePrice,
      handleProductPurchaseDate,
      selectedProduct,
      serial,
    } = this.props

    const { category } = selectedProduct
    return (
      <Fragment>
        <small>
          <b>Note: </b>Product details cannot be updated after a claim is made on the product.
        </small>
        <Table responsive>
          <thead>
            <tr>
              <th>Manufacturer</th>
              <th>Industry</th>
              <th>Subcategory</th>
              <th>Model Number</th>
              <th>Serial</th>
              <th>Purchase Date</th>
              <th>Purchase Price</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ cursor: 'initial' }}>
              <td>
                <Form.Control
                  className={!isBrandValidated ? null : isBrandValid ? 'is-valid' : 'is-invalid'}
                  autoComplete='off'
                  as='select'
                  id='brand'
                  size='sm'
                  onChange={(event) => {
                    handleInputChange(event)
                  }}
                  value={brand}
                >
                  <option key='' value=''>
                    Select manufacturer...
                  </option>
                  {manufacturers.map((mfg) => (
                    <option key={mfg.brand} value={mfg.brand.toUpperCase()}>
                      {mfg.brand.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  Please select a manufacturer.
                </Form.Control.Feedback>
              </td>
              <td style={{ fontSize: 'small' }}>{category}</td>
              <td>
                <Form.Control
                  className={
                    !isSubcategoryValidated ? null : isSubcategoryValid ? 'is-valid' : 'is-invalid'
                  }
                  autoComplete='off'
                  id='subCategory'
                  as='select'
                  size='sm'
                  onChange={(event) => {
                    handleInputChange(event)
                  }}
                  value={subCategory}
                  disabled={this.getSubcategoryDisabled()}
                >
                  <option key='' value=''>
                    Select subcategory...
                  </option>
                  {subcategories.map((subcategory) => (
                    <option
                      key={`${subcategory.subCatCode.toUpperCase()}${subcategory.subCatDescription.toUpperCase()}`}
                      value={subcategory.subCatDescription.toUpperCase()}
                    >
                      {subcategory.subCatDescription.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  Please select a subcategory.
                </Form.Control.Feedback>
              </td>
              <td>
                <Form.Control
                  className={!isModelValidated ? null : isModelValid ? 'is-valid' : 'is-invalid'}
                  id='model'
                  autoComplete='off'
                  type='text'
                  placeholder='model'
                  value={model}
                  size='sm'
                  onChange={(event) => {
                    handleInputChange(event)
                  }}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide the model number.
                </Form.Control.Feedback>
              </td>
              <td>
                <Form.Control
                  className={!isSerialValidated ? null : isSerialValid ? 'is-valid' : 'is-invalid'}
                  autoComplete='off'
                  id='serial'
                  type='text'
                  placeholder='serial'
                  value={serial}
                  size='sm'
                  onChange={(event) => {
                    handleInputChange(event)
                  }}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a serial.
                </Form.Control.Feedback>
              </td>
              <td>
                <DatePicker
                  as={Form.Control}
                  selected={new Date()}
                  onSelect={(date) => handleProductPurchaseDate(date)}
                  value={productPurchaseDate}
                  maxDate={new Date()}
                  disabledKeyboardNavigation
                  className='purchaseDate'
                  aria-describedby='purchaseDateHelpBlock'
                />
              </td>
              <td>{`$${purchasePrice}`}</td>
              <td>{this.renderResetButton()}</td>
              <td>{this.renderUpdateButton()}</td>
            </tr>
          </tbody>
        </Table>
      </Fragment>
    )
  }
}

export default Product
